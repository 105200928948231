import React from 'react';
import $ from 'jquery';
import moment from 'moment';
import 'moment/locale/fr';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheck, faTimes, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import Meter from './Meter';
import LoginModal from './LoginModal';
import DataModal from './DataModal';
import ToastMessage from './ToastMessage';
import SheetDetailModal from './SheetDetailModal';

class Sanitary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            time: Date.now(),
            meters: [],
            news: undefined,
            counter: 0,
            images: [],
            nextTimeslot: {},
            projectId: undefined,
            recordId: undefined,
            accessToken: undefined,
            projectConfig: undefined,
            sheetList: undefined,
            reportSuccessfullyUploaded: false,
            successfullyDisconnected: false,
            sheetDetailModal: undefined,
            ttl: 900,
            refresh: 600
        }
        this.project = this.props.project;
        this.dashboard = this.props.dashboard;
        this.record = this.props.record

        // this.host = 'http://localhost:8000'
        this.host = 'https://buildsense.fr'
        // this.host = 'https://rc.buildsense.fr'

        this.url = `${this.host}/api/project/${this.project}/open-dashboard/${this.dashboard}/`;
    }

    componentDidMount() {
        this.intervalUpdateData = setInterval(() => {
            this.updateData();
        }, 10000);
        $('#dataMod').on('hidden.bs.modal', () => {
            this.setState({
                accessToken: undefined,
            })
        });
        this.updateData();
    }

    componentWillUnmount() {
        clearInterval(this.intervalUpdateData);
        clearInterval(this.intervalUpdateLastSheets);
    }

    parseDateTime(string) {
        moment.locale('fr');
        return moment(string).format('L LT');
    }

    updateData() {
        $.getJSON(this.url).then((json) => {
            let meters = [];
            let news = this.state.news;
            let images = [];
            let title = this.state.title;
            let nextTimeslot = this.state.nextTimeslot;
            let projectId = this.state.projectId;

            if (!projectId && json && json.project !== null) {
                projectId = json.project.id;
            }
            json.indicators.forEach((indicator) => {
                if (indicator.type === "meter") {
                    meters.push(indicator);
                }
                if (indicator.type === "news") {
                    news = indicator;
                }
                if (indicator.type === "image") {
                    images.push(indicator);
                }
                if (indicator.type === "projectConfig") {
                    projectId = indicator.units.project_pk;
                    this.setState({
                        projectConfig: indicator.data
                    })
                }
                if (indicator.type === "recordList") {
                    let data = [];
                    if (Array.isArray(indicator.data)) {
                        data = indicator.data.sort((a, b) => {
                            if (new Date(a.datetime) < new Date(b.datetime)) {
                                return 1
                            }
                            return -1
                        })
                    }
                    this.setState({
                        sheetList: data
                    })
                    if (data.length >= 0 && data[0] !== undefined) {
                        this.setState({
                            recordId: data[0].record
                        })
                    } else {
                        this.setState({
                            recordId: this.record,
                        })
                    }
                }
                if (indicator.type === "textarea") {
                    if (indicator.title === "title") {
                        title = indicator.data;
                    }
                    if (indicator.title === "timeout" && indicator.data) {
                        let update = {};
                        if (indicator.data.ttl && Number.isInteger(indicator.data.ttl)) {
                            update.ttl = indicator.data.ttl;
                        }
                        if (indicator.data.refresh && Number.isInteger(indicator.data.refresh)) {
                            update.refresh = indicator.data.refresh;
                        }
                        this.setState(update);
                    }
                }
                if (indicator.type === "nextTimeslot") {
                    nextTimeslot = indicator.data
                }
            });
            let counter = 0;
            if (this.state.news)
                counter = (this.state.counter + 1) % news.data.length;
            this.setState({
                time: Date.now(),
                meters: meters,
                news: news,
                counter: counter,
                images: images,
                title: title,
                nextTimeslot: nextTimeslot,
                projectId: projectId,
            });
        });
    }

    validationIcon(predicat) {
        if (predicat) {
            return <FontAwesomeIcon icon={ faCheck } size="lg" fixedWidth></FontAwesomeIcon>
        }
        return <FontAwesomeIcon icon={ faTimes } size="lg" fixedWidth></FontAwesomeIcon>
    }

    openSheetDetailModal(sheet) {
        this.setState({
            sheetDetailModal: sheet
        })
    }

    closeSheetDetailModal() {
        this.setState({
            sheetDetailModal: undefined
        })
    }

    autoRoundFloat(value, option=null) {
        // Round the data magnitude
        if (typeof value === 'number') {
            var sign = 1;
            if (value !== 0) {
                if (value < 0) {
                    sign = -1;
                    value = -value;
                }
                if (option) {
                    value = value.toFixed(option);
                } else {
                    value = parseFloat(value);
                    var exponent = Math.floor(Math.log10(value));
                    if (exponent >= 1 && exponent < 3){
                        value = value.toFixed(Math.abs(exponent-2));
                    } else if (exponent < 1){
                        value = value.toFixed(Math.abs(exponent-1));
                    } else {
                        value = this.autoRoundFloat(value / Math.pow(10, exponent), 2) * Math.pow(10, exponent);
                        if (exponent > 2) {
                            value = Math.round(value);
                        }
                    }
                }
            }
            return Number(sign*value)
        }
        return 0
    }

    // Helper function
    getRoundedMeterData(meter) {
        if (Array.isArray(meter.data.data) && meter.data.data.length === 0) {
            return "--";
        }
        const rawData = meter.data.data * meter.units.conversionFactor;
        return this.autoRoundFloat(parseFloat(rawData));
    }
    
    getListClassFromDataSheet(sheet) {
        const alert = 'list-alert';
        const warning = 'list-warning';
        const success = 'list-success';

        let waterTreatmentActive = undefined;
        if (sheet.data.water_treatment_active) {
            waterTreatmentActive = sheet.data.water_treatment_active.value;
        }
        let waterTreatmentCombined = undefined;
        if (sheet.data.water_treatment_combined) {
            waterTreatmentCombined = sheet.data.water_treatment_combined.value;
        }
        let waterPh = undefined;
        if (sheet.data.water_pH) {
            waterPh = sheet.data.water_pH.value;
        }
        let waterTemperature = undefined;
        if (sheet.data.water_temperature) {
            waterTemperature = sheet.data.water_temperature.value;
        }
        let waterTransparency = undefined;
        if (sheet.data.transparency) {
            waterTransparency = sheet.data.transparency.value;
        }

        if (waterTreatmentActive <= 0.3 || waterTreatmentActive >= 5.4) { return alert; }
        if (waterTreatmentCombined >= 0.8) { return alert; }
        if (waterPh <= 5 || waterPh >= 8.5) { return alert; }
        if (waterTemperature >= 36) { return alert; }

        if (waterTransparency !== "Bonne") { return warning; }
        if (waterTreatmentActive >= 0.3 && waterTreatmentActive <= 0.4) { return warning; }
        if (waterTreatmentActive >= 1.4 && waterTreatmentActive <= 5.4) { return warning; }
        if (waterTreatmentCombined >= 0.6 && waterTreatmentCombined <= 0.8) { return warning; }
        if (waterPh >= 5 && waterPh <= 6.9) { return warning; }
        if (waterPh >= 7.7 && waterPh <= 8.5) { return warning; }
        if (waterTemperature >= 33 && waterTemperature <= 36) { return warning; }

        return success
    }

    displayDefaultMessage() {
        if (!this.state.sheetList || this.state.sheetList.length === 0)
            return <p>Aucun élément pour le moment. Ajoutez-en un à l'aide du bouton ci-dessus</p>
    }
    
    isConnected() {
        let session = window.localStorage.getItem("loginMod");
        if (session !== undefined) {
            session = JSON.parse(session);
            if (session) {
                const timestamp_now = new Date().getTime();
                const timestamp = session.timestamp;
                const delta = timestamp_now - timestamp
                if (delta/1000 > this.props.ttl) {
                    return false;
                }
                return true;
            }
            return false
        }
        return false;
    }

    render() {
        return (
            <div className="container-fluid">
                <br/>
                { this.state.reportSuccessfullyUploaded && <ToastMessage
                        timeout="5000" // timeout in ms
                        content="Votre rapport a été enregistré"
                        onClose={() => this.setState({ reportSuccessfullyUploaded: false })}
                        onTimeout={() => this.setState({ reportSuccessfullyUploaded: false })}
                    >
                    </ToastMessage>
                }

                { this.state.successfullyDisconnected && <ToastMessage
                        timeout="8000" // timeout in ms
                        content="Vous êtes déconnecté"
                        onClose={() => this.setState({ successfullyDisconnected: false })}
                        onTimeout={() => this.setState({ successfullyDisconnected: false })}
                    >
                    </ToastMessage>
                }

                <div className="row">
                    <div className="col-8">
                        <img src={process.env.PUBLIC_URL + '/logo.png'} height="100" className="pb-4" alt=""/>
                    </div>
                    <div className="col-4">
                        <h1 className="text-right">{ this.state.title }</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="d-none d-md-block col-md-8">
                        <div className="row pt-1">
                            {
                                this.state.meters.filter((meter) => {
                                    if (meter && meter.extra_config && meter.extra_config.touch) {
                                        const row = meter.extra_config.touch.row;
                                        return row === undefined || row === null || row.toString() === "1";
                                    }
                                    return true;
                                }).map((meter) => {
                                    return <Meter
                                        key={ meter.id }
                                        title={ meter.title }
                                        extra_config={ meter.extra_config }
                                        data={ `${ this.getRoundedMeterData(meter) } ${meter.units.display.label}` }>
                                    </Meter>;
                                })
                            }
                        </div>
                        <div className="row pt-1">
                            {
                                this.state.meters.filter((meter) => {
                                    if (meter && meter.extra_config && meter.extra_config.touch && meter.extra_config.touch.row) {
                                        return meter.extra_config.touch.row.toString() === "2";
                                    }
                                    return false;
                                }).map((meter) => {
                                    return <Meter
                                        key={ meter.id }
                                        title={ meter.title }
                                        extra_config={ meter.extra_config }
                                        data={ `${ this.getRoundedMeterData(meter) } ${meter.units.display.label}` }>
                                    </Meter>;
                                })
                            }
                        </div>
                    </div>
                    <div className="col-12 col-md-4 shadow-lg p-3 mb-5 bg-white rounded" style={{"height": "87vh", "overflowY": "auto"}}>
                        <h2>Derniers relevés
                            <button title="Ajouter un nouveau relevé" type="button" className="btn btnblue float-right pb-1" onClick={() => $('#loginMod').modal({ show: true }) }>
                                <FontAwesomeIcon icon={ faPlus } size="lg"></FontAwesomeIcon>
                            </button>
                        
                            { this.isConnected() &&
                                <button title="Se déconnecter" type="button" className="btn btn-outline-danger float-right mr-2"
                                    onClick={() => {
                                        window.localStorage.removeItem("loginMod");
                                        this.setState({ successfullyDisconnected: true });
                                    }}>
                                    <FontAwesomeIcon icon={ faArrowLeft } size="lg"></FontAwesomeIcon>
                                </button>
                            }
                        </h2>
                        <ul className="timeline">
                            {
                                this.state.sheetList && this.state.sheetList.map(sheet => {
                                    return (
                                        <li key={ sheet.pk } className={ this.getListClassFromDataSheet(sheet) } onClick={() => this.openSheetDetailModal(sheet) }>
                                            <span>{ sheet.data.pool_name.value || "" }</span>
                                            <span className="float-right">{ this.parseDateTime(sheet.datetime) }</span>
                                            <ul className="innerlist">
                                                <li>
                                                    { sheet.data.water_treatment_active && sheet.data.water_treatment_active.value && this.validationIcon(sheet.data.water_treatment_active.value >= 0.4 && sheet.data.water_treatment_active.value <= 1.4) }
                                                    { sheet.data.water_treatment_active && sheet.data.water_treatment_active.value && sheet.data.water_treatment_active.name }
                                                </li>
                                                <li>
                                                    { sheet.data.water_treatment_combined && sheet.data.water_treatment_combined.value && this.validationIcon(sheet.data.water_treatment_combined.value <= 0.6) }
                                                    { sheet.data.water_treatment_combined && sheet.data.water_treatment_combined.value && sheet.data.water_treatment_combined.name }
                                                </li>
                                                <li>
                                                    { sheet.data.water_pH && sheet.data.water_pH.value && this.validationIcon(sheet.data.water_pH.value >= 6.9 && sheet.data.water_pH.value <= 7.7) }
                                                    { sheet.data.water_pH && sheet.data.water_pH.value && sheet.data.water_pH.name }
                                                </li>
                                                <li>
                                                    { sheet.data.water_temperature && sheet.data.water_temperature.value && this.validationIcon(sheet.data.water_temperature.value <= 33) }
                                                    { sheet.data.water_temperature && sheet.data.water_temperature.value && sheet.data.water_temperature.name }
                                                </li>
                                                <li>
                                                    { sheet.data.water_temperature && sheet.data.transparency.value && this.validationIcon(sheet.data.transparency.value === "Bonne") }
                                                    { sheet.data.water_temperature && sheet.data.transparency.value && sheet.data.transparency.name }
                                                </li>
                                            </ul>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        {
                            this.displayDefaultMessage()
                        }
                    </div>
                </div>
                

                <LoginModal id="loginMod" host={ this.host } refresh={ this.state.refresh } ttl={ this.state.ttl } onConnectionSuccessful={(data) => {
                    this.setState({
                        accessToken: data.access
                    })
                    $('#dataMod').modal('show');
                }}></LoginModal>

                <DataModal
                    id="dataMod"
                    host={ this.host }
                    data={ this.state.projectConfig }
                    access={ this.state.accessToken }
                    projectId={ this.state.projectId }
                    recordId={ this.state.recordId }
                    onFormCompleted={(data) => {
                        this.setState({
                            accessToken: undefined,
                            reportSuccessfullyUploaded: true,
                        })
                    }}></DataModal>

                <SheetDetailModal id="sheetDetailModal" show={ this.state.sheetDetailModal !== undefined } sheet={ this.state.sheetDetailModal } onClose={() => {
                    this.closeSheetDetailModal();
                }}></SheetDetailModal>
            </div>
        );
    }
}

export default Sanitary;
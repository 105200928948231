import React from 'react';
import './App.css';
import $ from 'jquery';

import Sanitary from './components/Sanitary';
import ProgressCircle from './components/ProgressCircle';

function getUriComponent(name) {
  const regexp = new RegExp('[?&]' + encodeURIComponent(name) + '=([^&]*)');
  const matches = regexp.exec(window.location.search);
  if (matches) {
    return decodeURIComponent(matches[1]);
  }
  return undefined;
}

function checkParameters() {
  const p = getUriComponent('p');
  const d = getUriComponent('d');

  if (p && d) {
    return <Sanitary project={ getUriComponent('p') } dashboard={ getUriComponent('d') } record={ getUriComponent('r') }></Sanitary> 
  }
  return <div>
    <img className="progress-logo" src={process.env.PUBLIC_URL + '/logo.png'} height="100" alt="Buildsense Logo"/>
    <ProgressCircle></ProgressCircle>
  </div>
}

function uploadRequests() {
  let requests = window.localStorage.getItem("requests");
  window.localStorage.removeItem("requests");
  if (requests) {
    requests = JSON.parse(requests);
    requests.forEach(request => {
      $.ajax({
        type: request.type,
        dataType: request.dataType,
        url: request.url,
        headers: request.headers,
        data: request.data,
        error: function(r, s, e) {
            if (r.readyState === 0) { // Network error
                let requests = window.localStorage.getItem("requests");
                if (requests) {
                    requests = JSON.parse(requests);
                } else {
                    requests = [];
                }
                requests.push(request);
                window.localStorage.setItem("requests", JSON.stringify(requests));
            }
        }
      });
    });
  }
  window.setTimeout(uploadRequests, 1000);
}

function App() {
  window.setTimeout(uploadRequests, 1000);

  return (
    <div className="App pl-4 pr-4">
      { checkParameters() }
    </div>
  );
}

export default App;
import React from 'react';

class ProgressCircle extends React.Component {
    render() {
        return <div>
            <svg className="progress-background" height="100" width="100">
                <circle  cx="50" cy="50" r="40" stroke="#cad6da" strokeWidth="3" fill="none"></circle>
            </svg>
            <svg className="progress-over" height="100" width="100">
                <circle  cx="50" cy="50" r="40" stroke="#22c0e8" strokeWidth="3" fill="none" >
                    <animate attributeType="CSS" attributeName="stroke-dasharray" from="1,254" to="247,56" dur="5s" repeatCount="indefinite" />
                </circle>
            </svg>
        </div>
    }
}

export default ProgressCircle;
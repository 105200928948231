import React from 'react';

class Meter extends React.Component {
    getMeterIcon() {
        let icon = "fa-home";
        const name = this.props.title;
        const extra_config = this.props.extra_config;
        let color = "#cad6da";

        if (name === "global_consumption") {
            icon = "fa-bolt";
            color = "#ffa300";
        } else if (name === "water_consumption") {
            icon = "fa-tint";
            color = "#00e497";
        } else if (name === "hall_air_temperature") {
            icon = "fa-thermometer";
            color = "#cad6da";
        } else if (name === "pool_water_temperature") {
            icon = "fa-thermometer";
            color = "#00d4ff";
        } else if (name === "pool_hall_temperature") {
            icon = "fa-thermometer";
            color = "#bcf4f7";
        } else if (name === "changing_room_temperature") {
            icon = "fa-thermometer";
            color = "#537fe6";
        }
        if (extra_config && extra_config.touch && extra_config.touch.color) {
            color = extra_config.touch.color;
        }
        if (extra_config && extra_config.touch && extra_config.touch.icon) {
            icon = extra_config.touch.icon;
        }
        return <div className="icon-wrapper rounded-circle" style={ {
            backgroundColor: color,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}>
            <i className={ "icon-white fa fa-2x " + icon }></i>
        </div>;
    }

    getMeterDataString() {
        return this.props.data;
    }

    getDisplayedTest() {
        const title = this.props.title;
        const extra_config = this.props.extra_config;

        if (extra_config && extra_config.touch && extra_config.touch.title) {
            return extra_config.touch.title;
        }
        if (title === "global_consumption") {
            return "Energie";
        } else if (title === "water_consumption") {
            return "Eau";
        } else if (title === "hall_air_temperature") {
            return "Air du hall";
        } else if (title === "pool_water_temperature") {
            return "Eau du bassin";
        } else if (title === "pool_hall_temperature") {
            return "Air du bassin";
        } else if (title === "changing_room_temperature") {
            return "Air des vestiaires"
        }
        return title;
    }

    render() {
        return (
            <div className="col-12 col-md-6 col-xl-4">
                <div className="card card-size shadow mb-3 bg-white rounded">
                    <div className="card-body row">
                        <div className="col-3 text-center">
                            { this.getMeterIcon() }
                        </div>
                        <div className="col-8 offset-md-1">
                            <h5 className="card-title">{ this.getDisplayedTest() }</h5>
                            <p className="card-text" dangerouslySetInnerHTML={{__html: this.getMeterDataString() }}></p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Meter;
import React from 'react';
import $ from 'jquery';
import * as d3 from 'd3';

import moment from 'moment';
import 'moment/locale/fr';

class SheetDetailModal extends React.Component {

    constructor(props) {
        super(props);
        this.id = "#" + this.props.id;
        this.danger = "#ff5e5e";
        this.warning = "#ffd775";
        this.ok = "#70d26d";
    }

    formatDate(rawDate) {
        return moment(rawDate).format('L LT');
    }

    changeModalVisibility(visible) {
        if (visible) {
            $(this.id).modal('show');
        } else {
            $(this.id).modal('hide');
        }
    }

    createGauge(selector, gaugeData) {
        d3.select(selector).selectAll('*').remove()
        var graph = d3.select(selector)
            .append("svg")
            .attr("width", '100%')
            .attr("height", 80);

        graph.selectAll("rect") 
            .data(gaugeData.data)
            .enter()
            .append("rect")
            .attr("width", (d, i) => ((d.e-d.s) * 100 / gaugeData.size) + '%')
            .attr("height", 20)
            .attr("x", (d, i) => (d.s * 100 / gaugeData.size) + '%')
            .attr("y", 30)
            .attr("fill", d => d.c)

        graph.selectAll("text")
            .data(gaugeData.data)
            .enter()
            .append("text")
            .text(d => d.e)
            .attr("x", (d, i) => (d.e * 100 / gaugeData.size) + '%')
            .attr("y", 70)
            .attr("text-anchor", (d, i) => {
                if (i === 0) { return "start"; }
                if (i === gaugeData.data.length - 1) { return "end"; }
                return "middle";
            })
            .attr("id", d => `gaugeThreshold${d.e}`)
            .attr("fill", d => d.c)

        // Draw current value bar and text
        graph.append("rect")
            .attr("width", 2)
            .attr("height", 30)
            .attr("x", (gaugeData.gauge * 100 / gaugeData.size) + '%')
            .attr("y", 25)
            .attr("fill", "black")

        graph.append("text")
            .text(gaugeData.gauge.toString())
            .attr("x", (gaugeData.gauge * 100 / gaugeData.size) + '%')
            .attr("y", 17)
            .attr("text-anchor", () => {
                if (gaugeData.gauge <= 5) { return "start"; }
                if (gaugeData.gauge >= gaugeData.size - 5) { return "end"; }
                return "middle";
            })
            .attr("id", "gaugeDescription")
    }

    createWaterTemperatureGauge(value) {
        if (value !== undefined) {
            value = parseFloat(value.toString().replace(',', '.'))
            const data = {
                size: 40,
                data: [
                    {s: 0, e: 33, c: this.ok},
                    {s: 33, e: 36, c: this.warning},
                    {s: 36, e: 40, c: this.danger}
                ],
                gauge: value
            }
            this.createGauge("#waterTemperature", data);
        }
    }

    createWaterPhGauge(value) {
        if (value !== undefined) {
            value = parseFloat(value.toString().replace(',', '.'))
            const data = {
                size: 15,
                data: [
                    {s: 0, e: 5, c: this.danger},
                    {s: 5, e: 6.9, c: this.warning},
                    {s: 6.9, e: 7.7, c: this.ok},
                    {s: 7.7, e: 8.5, c: this.warning},
                    {s: 8.5, e: 15, c: this.danger},
                ],
                gauge: value
            }
            this.createGauge("#waterPh", data);
        }
    }

    createWaterTreatmentActiveGauge(value) {
        if (value !== undefined) {
            value = parseFloat(value.toString().replace(',', '.'))
            const data = {
                size: 6,
                data: [
                    {s: 0, e: 0.3, c: this.danger},
                    {s: 0.3, e: 0.4, c: this.warning},
                    {s: 0.4, e: 1.4, c: this.ok},
                    {s: 1.4, e: 5.4, c: this.warning},
                    {s: 5.4, e: 6, c: this.danger},
                ],
                gauge: value
            }
            this.createGauge("#waterTreatmentActive", data);
        }
    }

    createWaterTreatmentCombinedGauge(value) {
        if (value !== undefined) {
            value = parseFloat(value.toString().replace(',', '.'))
            const data = {
                size: 1.5,
                data: [
                    {s: 0, e: 0.6, c: this.ok},
                    {s: 0.6, e: 0.8, c: this.warning},
                    {s: 0.8, e: 1.5, c: this.danger},
                ],
                gauge: value
            }
            this.createGauge("#waterTreatmentCombined", data);
        }
    }

    componentDidMount() {
        this.changeModalVisibility(this.props.show);
        $(this.id).on('hidden.bs.modal', (e) => {
            if (this.props.onClose) {
                this.props.onClose();
            }
        })
    }

    componentDidUpdate() {
        this.changeModalVisibility(this.props.show);
        if (this.props.sheet) {
            if (this.props.sheet.data.water_temperature !== undefined) {
                this.createWaterTemperatureGauge(this.props.sheet.data.water_temperature.value);
            }
            if (this.props.sheet.data.water_pH !== undefined) {
                this.createWaterPhGauge(this.props.sheet.data.water_pH.value);
            }
            if (this.props.sheet.data.water_treatment_active !== undefined) {
                this.createWaterTreatmentActiveGauge(this.props.sheet.data.water_treatment_active.value);
            }
            if (this.props.sheet.data.water_treatment_combined !== undefined) {
                this.createWaterTreatmentCombinedGauge(this.props.sheet.data.water_treatment_combined.value);
            }
        }
    }

    displayValueOrErrorMsg = (value) => {
        if (value !== undefined) {
            return value;
        }
        return 'Pas de valeur';
    }
    
    render() {
        return (
            <div id={ this.props.id } className="modal bd-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        {
                            this.props.sheet && <div className="modal-body">
                                <h1>
                                    { this.props.sheet.data.pool_name && this.displayValueOrErrorMsg(this.props.sheet.data.pool_name.value) }
                                    { !this.props.sheet.data.pool_name && "Pas de valeur" }
                                </h1>
                                <small id="emailHelp" className="form-text text-muted">
                                    Relevé effectué par { this.props.sheet.user }, le { this.formatDate(this.props.sheet.datetime) }
                                </small>
                                <br/>
                                <h3>Prélèvements de qualité de l'eau</h3>

                                <p>Transparence:
                                    { this.props.sheet.data.transparency && " " + this.displayValueOrErrorMsg(this.props.sheet.data.transparency.value) }
                                    { !this.props.sheet.data.transparency && "Pas de valeur" }
                                </p>
                                <p>Température de l'air:
                                    { this.props.sheet.data.air_temperature && " " + this.displayValueOrErrorMsg(this.props.sheet.data.air_temperature.value)}
                                    { this.props.sheet.data.air_temperature && this.props.sheet.data.air_temperature.value && " °C" }
                                    { !this.props.sheet.data.air_temperature && "Pas de valeur" }
                                </p>
                                <p>Température de l'eau:
                                    { this.props.sheet.data.water_temperature && " " + this.displayValueOrErrorMsg(this.props.sheet.data.water_temperature.value)}
                                    { this.props.sheet.data.water_temperature && this.props.sheet.data.water_temperature.value && " °C" }
                                    { !this.props.sheet.data.water_temperature && "Pas de valeur" }
                                </p>
                                <div id="waterTemperature"></div>
                                <hr/>


                                <p>pH:
                                    { this.props.sheet.data.water_pH && " " + this.displayValueOrErrorMsg(this.props.sheet.data.water_pH.value) }
                                    { !this.props.sheet.data.water_pH && "Pas de valeur" }
                                </p>
                                <div id="waterPh"></div>
                                <hr/>


                                <h3>Analyse chlore ou brome</h3>
                                <p>Stabilisant:
                                    { this.props.sheet.data.water_treatment_stabilizer && " " + this.displayValueOrErrorMsg(this.props.sheet.data.water_treatment_stabilizer.value) }
                                    { this.props.sheet.data.water_treatment_stabilizer && this.props.sheet.data.water_treatment_stabilizer.unit }
                                    { !this.props.sheet.data.water_treatment_stabilizer && "Pas de valeur" }
                                </p>
                                <p>DPD1:
                                    { this.props.sheet.data.water_treatment_dpd1 && " " + this.displayValueOrErrorMsg(this.props.sheet.data.water_treatment_dpd1.value) }
                                    { this.props.sheet.data.water_treatment_dpd1 && this.props.sheet.data.water_treatment_dpd1.unit }
                                    { !this.props.sheet.data.water_treatment_dpd1 && "Pas de valeur" }
                                </p>
                                <p>DPD1+DPD3:
                                    { this.props.sheet.data.water_treatment_total_dpd1_dpd3 && " " + this.displayValueOrErrorMsg(this.props.sheet.data.water_treatment_total_dpd1_dpd3.value) }
                                    { this.props.sheet.data.water_treatment_total_dpd1_dpd3 && this.props.sheet.data.water_treatment_total_dpd1_dpd3.unit }
                                    { !this.props.sheet.data.water_treatment_total_dpd1_dpd3 && "Pas de valeur" }
                                </p>

                                <p>Chlore libre actif:
                                    { this.props.sheet.data.water_treatment_active && " " + this.displayValueOrErrorMsg(this.props.sheet.data.water_treatment_active.value) }
                                    { this.props.sheet.data.water_treatment_active && this.props.sheet.data.water_treatment_active.unit }
                                    { !this.props.sheet.data.water_treatment_active && "Pas de valeur" }
                                </p>
                                <div id="waterTreatmentActive"></div>
                                <hr/>

                                <p>Combiné (chloramines):
                                    { this.props.sheet.data.water_treatment_combined && " " + this.displayValueOrErrorMsg(this.props.sheet.data.water_treatment_combined.value) }
                                    { this.props.sheet.data.water_treatment_combined && this.props.sheet.data.water_treatment_combined.unit }
                                    { !this.props.sheet.data.water_treatment_combined && "Pas de valeur" }
                                </p>
                                <div id="waterTreatmentCombined"></div>
                            </div>
                        }
                        
                    </div>
                </div>
            </div>
        );
    }
}

export default SheetDetailModal;
import React from 'react';
import $ from 'jquery';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from "@fortawesome/free-solid-svg-icons";

class LoginModal extends React.Component {

    componentDidMount() {
        $(`#${ this.props.id }`).on('shown.bs.modal', this.modalOpen.bind(this));
    }
    
    modalOpen() {
        let session = window.localStorage.getItem(this.props.id);
        if (session !== undefined) {
            session = JSON.parse(session);
            if (session) {
                const timestamp_now = new Date().getTime();
                const timestamp = session.timestamp;
                const delta = timestamp_now - timestamp
                const json = session.data;
                if (delta/1000 > this.props.ttl) {
                    this.removeTokenFromLocalStorage();
                } else {
                    if (delta > this.props.refresh) {
                        this.refreshTokens(json.refresh);
                    }
                    $(`#${ this.props.id }`).modal('hide');
                    this.props.onConnectionSuccessful(json);
                }
            }
        }
    }

    refreshTokens(refresh_token) {
        const data = {
            "refresh": refresh_token
        }
        $.post(`${ this.props.host }/api/auth/refresh/`, data, (json) => {
            if (json && json.access) {
                window.localStorage.setItem(this.props.id, JSON.stringify({
                    timestamp: new Date().getTime(),
                    data: {
                        refresh: refresh_token,
                        access: json.access
                    }
                }));
            }
        })
    }

    removeTokenFromLocalStorage() {
        window.localStorage.removeItem(this.props.id);
    }

    render() {
        return (
            <div id={ this.props.id } className="modal bd-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Nouveau relevé d'eau: Connexion</h5>
                        </div>
                        <div className="modal-body">
                            <form>
                                <h1>Connexion</h1>
                                <div className="form-group">
                                    <label htmlFor="username">Nom d'utilisateur</label>
                                    <input
                                        id="username"
                                        className="form-control pb-1"
                                        type="text"
                                        placeholder="Nom d'utilisateur" required/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Mot de passe</label>
                                    <input id="password" className="form-control " type="password" placeholder="Mot de passe" required/>
                                </div>

                                <button className="btn btn-primary form-control" type="submit" onClick={(evt) => {
                                    evt.preventDefault();
                                    const data = {
                                        username: $("#username").val(),
                                        password: $("#password").val(),
                                    };
                                    $.post(`${ this.props.host }/api/auth/`, data, (json) => {
                                        if (json && json.refresh && json.access) {
                                            $('#username').val("");
                                            $('#password').val("");
                                            $("#logErrorMsg").css("display", "none");
                                            $('#' + this.props.id ).modal('hide');
                                            window.localStorage.setItem(this.props.id, JSON.stringify({
                                                "timestamp": new Date().getTime(),
                                                "data": json
                                            }));
                                            if (this.props.onConnectionSuccessful) {
                                                this.props.onConnectionSuccessful(json);
                                            }
                                        }
                                    }, "json").fail(() => {
                                        $('#username').val("");
                                        $('#password').val("");
                                        $("#logErrorMsg").css("display", "block");
                                    })
                                }}>
                                    <FontAwesomeIcon icon={faSave} size="lg" className="pr-2"></FontAwesomeIcon>
                                    Connexion
                                </button>
                            </form>
                        </div>
                        <div id="logErrorMsg" className="modal-footer" style={{ display: "none" }}>
                            <div className="alert alert-danger" role="alert">
                                Mauvais nom d'utilisateur ou mot de passe.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoginModal;
import React from 'react';

class ToastMessage extends React.Component {
    componentDidMount() {
        if (this.props.onTimeout && this.props.timeout) {
            this.timeout = window.setTimeout(() => this.props.onTimeout(), this.props.timeout);
        }
    }

    componentWillUnmount() {
        if (this.timeout) {
            window.clearTimeout(this.timeout);
        }
    }

    render() {
        return <div className="alert alert-success alert-dismissible fade show" style={{ position: 'fixed', zIndex: 1}} role="alert">
            { this.props.content }
            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => {
                if (this.props.onClose) {
                    this.props.onClose();
                } else {
                    console.warn('ToastMessage: this.props.onClose not provided');
                }
            }}>
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    }
}

export default ToastMessage;
import React from 'react';
import $ from 'jquery';
import moment from 'moment';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Datetime from "react-datetime";

class DataModal extends React.Component {
    constructor(props) {
        super(props);
        this.defaultDateTime = this.getDateTime();
        this.state = {
            errorMsg: undefined,
            errorFields: [],
            dateField: new Date(),
        }
        this.host = this.props.host;
    }

    // Get current date time as string
    getDateTime() {
        const datetime = moment().format('Y-MM-DDThh:mm');
        return datetime;
    }

    // Get available pool list from data provided
    getPoolNameList() {
        if (this.props.data && this.props.data.components && this.props.data.components.pools) {
            return this.props.data.components.pools.map((pool) => {
                return <option key={ pool.id } defaultValue={ pool.name }>{ pool.name }</option>
            })
        }
        return;
    }

    // Get available pool list from data provided
    getPoolIdList() {
        if (this.props.data && this.props.data.components && this.props.data.components.pools) {
            return this.props.data.components.pools.map((pool) => {
                return <option key={ pool.id } defaultValue={ pool.id }>{ pool.id }</option>
            })
        }
        return;
    }
    

    // Clear input form
    // Maybe just replace with a form.reset
    clearForm() {
        const ids = [
            "sampling_point",
            "air_temperature",
            "water_temperature",
            "water_pH",
            "water_treatment_dpd1",
            "water_treatment_total_dpd1_dpd3",
            "water_treatment_stabilizer"
        ];
        ids.forEach((id) => document.getElementById(id).value = "");
    }

    // Retrieve Form Data as JSON
    getJSONData() {
        let sampling_point = undefined;
        if (document.getElementById("sampling_point").value.trim().length !== 0) {
            sampling_point = {
                "sampling_point__value": document.getElementById("sampling_point").value,
                "sampling_point__name": "Point de prélèvement",
                "sampling_point__type": "string",
                "sampling_point__key": "sampling_point",
            }
        }
        let poolName = document.getElementById("pool_name").value;
        let poolId = undefined;
        const pool = this.props.data.components.pools.find(e => e.name === poolName);
        if (pool) {
            poolName = pool.name;
            poolId = pool.id;
        }
        const data = {
            "type": "water_quality",
            "datetime": moment(this.state.dateField).format(),
            "pool_name__value": poolName.toString(),
            "pool_name__name": "Nom du bassin",
            "pool_name__type": "string",
            "pool_name__key": "pool_name",
            "pool_id__value": poolId.toString(),
            "pool_id__name": "Id du bassin",
            "pool_id__type": "id",
            "pool_id__key": "pool_id",
            "air_temperature__value": document.getElementById("air_temperature").value,
            "air_temperature__unit": "degC",
            "air_temperature__type": "float",
            "air_temperature__name": "Température de l'air",
            "air_temperature__key": "air_temperature",
            "transparency__value": document.getElementById("transparency").value,
            "transparency__name": "Transparence",
            "transparency__type": "string",
            "transparency__key": "transparency",
            "water_temperature__value": document.getElementById("water_temperature").value,
            "water_temperature__unit": "degree",
            "water_temperature__type": "float",
            "water_temperature__name": "Température de l'eau",
            "water_temperature__key": "water_temperature",
            "water_pH__value": document.getElementById("water_pH").value,
            "water_pH__type": "float",
            "water_pH__name": "Mesure pH",
            "water_pH__key":  "water_pH",
            "water_treatment_dpd1__value": document.getElementById("water_treatment_dpd1").value,
            "water_treatment_dpd1__unit": "mg/l",
            "water_treatment_dpd1__type": "float",
            "water_treatment_dpd1__name": "DPD 1",
            "water_treatment_dpd1__key": "water_treatment_dpd1",
            "water_treatment_total_dpd1_dpd3__value": document.getElementById("water_treatment_total_dpd1_dpd3").value,
            "water_treatment_total_dpd1_dpd3__unit": "mg/l",
            "water_treatment_total_dpd1_dpd3__type": "float",
            "water_treatment_total_dpd1_dpd3__name": "DPD 1 + DPD 3",
            "water_treatment_total_dpd1_dpd3__key": "water_treatment_total_dpd1_dpd3",
            "water_treatment_stabilizer__value": document.getElementById("water_treatment_stabilizer").value,
            "water_treatment_stabilizer__unit": "mg/l",
            "water_treatment_stabilizer__type": "float",
            "water_treatment_stabilizer__name": "Stabilisant",
            "water_treatment_stabilizer__key": "water_treatment_stabilizer",
            "water_treatment_available__unit": "mg/l",
            "water_treatment_available__type": "float",
            "water_treatment_available__name": "Chlore libre actif",
            "water_treatment_available__key": "water_treatment_available",
            "water_treatment_combined__unit": "mg/l",
            "water_treatment_combined__type": "float",
            "water_treatment_combined__name": "Combiné (chloramines)",
            "water_treatment_combined__key": "water_treatment_combined",
            "water_treatment_active__unit": "mg/l",
            "water_treatment_active__type": "float",
            "water_treatment_active__name": "Chlore libre actif",
            "water_treatment_active__key": "water_treatment_active"
        };
        if (sampling_point) {
            return ({...data, ...sampling_point})
        }
        return (data);
    }

    formIsValid() {
        const ids = [
            "date",
            "air_temperature",
            "transparency",
            "water_temperature",
            "water_pH",
            "water_treatment_dpd1",
            "water_treatment_total_dpd1_dpd3",
            "water_treatment_stabilizer"
        ];
        for (const id of ids) {
            if (id !== "date") {
                if ($(`#${id}`).val().trim().length === 0) {
                    return false;
                }
            }
        }
        return true;
    }

    uploadResult() {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${ this.props.access }`
        }
        if (this.props.projectId && this.props.recordId) {
            const projectId = this.props.projectId;
            const recordId = this.props.recordId;
            const urlCreateSheet = `${this.host}/api/project/${ projectId }/record/${recordId}/sheet/create/`;

            let request = {
                type: "POST",
                dataType: "json",
                url: urlCreateSheet,
                headers: headers,
                data: JSON.stringify(this.getJSONData())
            }

            let that = this;
            $.ajax({
                type: request.type,
                dataType: request.dataType,
                url: request.url,
                headers: request.headers,
                data: request.data,
                error: function(r, s, e) {
                    if (r.readyState === 0) { // Network error
                        let requests = window.localStorage.getItem("requests");
                        if (requests) {
                            requests = JSON.parse(requests);
                        } else {
                            requests = [];
                        }
                        requests.push(request);
                        window.localStorage.setItem("requests", JSON.stringify(requests));
                        that.hideModal();
                    }
                }
            }).then((data) => {
                if (data && data.message === "error") {
                    $("#dataApiErrorMsg").css("display", "block");
                } else if (data && data.message === "input error") {
                    let msg = `Erreur dans le formulaire`;
                    this.setState({ errorFields: data.fields, errorMsg: msg });
                } else {
                    this.onUploadSuccessful();
                }
            }).fail((data) => {
                if (data && data.responseJSON) {
                    if (data.responseJSON.message === "input error") {
                        let msg = `Erreur dans le formulaire`;
                        this.setState({ errorFields: data.responseJSON.fields, errorMsg: msg });
                    }
                }
            })
        } else {
            alert("Une erreur s'est produite. Vous pouvez essayer de nouveau dans quelques instants. Si cette erreur persiste, merci de contacter les équipes techniques d'Ecotropy.")
        }
    }

    hideModal() {
        $('#dataMod').modal('hide');
    }

    onUploadSuccessful() {
        this.setState({
            errorMsg: undefined,
            errorFields: [],
        })
        this.clearForm();
        this.hideModal();
        if (this.props.onFormCompleted) {
            this.props.onFormCompleted();
        }
    }

    checkErrorField(fieldName) {
        return this.state.errorFields.includes(fieldName);
    }

    dateTimeChange(value) {
        this.setState({
            dateField: value
        })
    }

    render() {
        return (
            <div id={ this.props.id } className="modal bd-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Nouveau relevé d'eau</h5>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label>Date de relevé</label>
                                    <Datetime defaultValue={ this.getDateTime() } onChange={ (val) => this.dateTimeChange(val) } />
                                </div>
                                
                                <div className="form-group">
                                    <label htmlFor="pool_name">Nom du bassin</label>
                                    <select className="form-control" name="pool_name" id="pool_name">
                                        { this.getPoolNameList() }
                                    </select>
                                </div>
                                
                                <h3>Prélèvement d'eau</h3> 
                                <div className="form-group">
                                    <label htmlFor="sampling_point">Point de prélèvement</label>
                                    <input id="sampling_point" className="form-control " type="text" placeholder="Point de prélèvement" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="air_temperature">Température de l'air en °C</label>
                                    <input id="air_temperature" className="form-control " type="text" placeholder="Température de l'air en °C" />
                                    { this.checkErrorField('air_temperature') &&
                                        <small id="airTemperatureHelp" className="form-text text-danger">Champ requis. La valeur doit être un nombre.</small>
                                    }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="transparency">Transparence</label>
                                    <select className="form-control" name="transparency" id="transparency">
                                        <option value="Bonne">Bonne</option>
                                        <option value="Dégradée">Dégradée</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="water_temperature">Température de l'eau en °C</label>
                                    <input id="water_temperature" className="form-control " type="text" placeholder="Température de l'eau en °C" />
                                    { this.checkErrorField('water_temperature') &&
                                        <small id="waterTemperatureHelp" className="form-text text-danger">Champ requis. La valeur doit être un nombre inférieure à 80.</small>
                                    }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="water_pH">Mesure pH</label>
                                    <input id="water_pH" className="form-control " type="text" placeholder="Mesure pH" />
                                    { this.checkErrorField('water_pH') && 
                                        <small id="waterPhHelp" className="form-text text-danger">Champ requis. La valeur doit être un nombre supérieure ou égale à 3 et inférieure ou égale à 12.</small>
                                    }
                                </div>

                                <hr/>

                                <h3>Mesure de Chlore</h3>
                                <div className="form-group">
                                    <label htmlFor="water_treatment_dpd1">DPD 1 en mg/l</label>
                                    <input id="water_treatment_dpd1" className="form-control " type="text" placeholder="DPD 1 en mg/l" />
                                    { this.checkErrorField('water_treatment_dpd1') &&
                                        <small id="waterTreatmentDpd1" className="form-text text-danger">Champ requis. La valeur doit être un nombre.</small>
                                    }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="water_treatment_total_dpd1_dpd3">Total (DPD1 + DPD3) en mg/l</label>
                                    <input id="water_treatment_total_dpd1_dpd3" className="form-control " type="text" placeholder="Total (DPD1 + DPD3) en mg/l" />
                                    { this.checkErrorField('water_treatment_total_dpd1_dpd3') &&
                                        <small id="waterTreatmentTotalDpd1Dpd3" className="form-text text-danger">Champ requis. La valeur doit être un nombre.</small>
                                    }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="water_treatment_stabilizer">Stabilisant en mg/l</label>
                                    <input id="water_treatment_stabilizer" className="form-control " type="text" placeholder="Stabilisant en mg/l" />
                                    { this.checkErrorField('water_treatment_stabilizer') &&
                                        <small id="waterTreatmentStabilizer" className="form-text text-danger">Champ requis. La valeur doit être un nombre.</small>
                                    }
                                </div>

                                <button className="btn btn-primary form-control"
                                    onClick={
                                        (evt) => {
                                            evt.preventDefault();
                                            this.setState({
                                                errorMsg: undefined,
                                                errorFields: []
                                            })
                                            if (this.formIsValid()) {
                                                $("#dataErrorMsg").css("display", "none");
                                                this.uploadResult();
                                            } else {
                                                $("#dataErrorMsg").css("display", "block");
                                            }
                                        }
                                    }>
                                    <FontAwesomeIcon icon={faCheck} size="lg" className="pr-2"></FontAwesomeIcon>
                                    Enregistrer le relevé
                                </button>
                            </form>
                        </div>
                        <div id="dataErrorMsg" className="modal-footer" style={{ display: "none" }}>
                            <div className="alert alert-danger" role="alert">
                                Tous les champs sont obligatoires.
                            </div>
                        </div>
                        <div id="dataApiErrorMsg" className="modal-footer" style={{ display: "none" }}>
                            <div className="alert alert-danger" role="alert">
                                Erreur de validation avec l'API.
                            </div>
                        </div>
                        {
                            this.state.errorMsg &&
                            <div id="dataApiFieldErrorMsg" className="modal-footer" style={{ display: "block" }}>
                                <div className="alert alert-danger" role="alert">
                                    { this.state.errorMsg }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default DataModal;